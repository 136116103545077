import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { administrationModalMiddlewareHistory } from './middleware/feature/administrationModal';
import { normalizeMiddleware } from './middleware/core/normalize';
import { notificationMiddleware } from './middleware/core/notification';
import { uiMiddlewareHistory } from './middleware/core/ui';
import { notifySettingsMiddleware } from './middleware/feature/notifySettings';
import { filtersMiddlewareHistory } from './middleware/feature/filters';
import { sharedDataMiddlewareHistory } from './middleware/feature/sharedData';
import { initializationMiddlewareHistory } from './middleware/feature/initialization';
import { modalMiddlewareHistory } from './middleware/feature/modal';
import { mainMiddleware } from './middleware/feature/main';
import { projectMiddlewareHistory } from './middleware/feature/project';
import { documentsMiddlewareHistory } from './middleware/feature/documents';
import { documentMiddlewareHistory } from './middleware/feature/document';

import { ApiConfigMiddleWare } from '@pwbapps/reduxcore';
import { AuthConfigMiddleWare } from '@pwbapps/reduxcore';
import { GraphQlConfigMiddleWare } from '@pwbapps/reduxcore';
import { LoggerConfigMiddleWare } from '@pwbapps/reduxcore';
import { MicrosoftApiConfigMiddleWare } from '@pwbapps/reduxcore';

import { modalReducer } from './reducers/modal';
import { uiReducer } from './reducers/ui';
import { administrationModalReducer } from './reducers/administrationModal'; 
import { notifySettingsReducer } from './reducers/notifySettings';
import { sortReducer } from './reducers/sort';
import { notificationReducer } from './reducers/notification';
import { filtersReducer } from './reducers/filters';
import { loaderReducer } from './reducers/loader';
import { sharedDataReducer } from './reducers/sharedData';
import { mainReducer } from './reducers/main';
import { projectReducer } from './reducers/project';
import { documentsReducer } from './reducers/documents';
import { documentReducer } from './reducers/document';
import { initializationReducer } from './reducers/initialization';

import { AuthConfigReducer } from '@pwbapps/reduxcore';


import { appName, clientId, graphApiScopes, isExternalApp, redirectUri, tenantId, tokenClientId } from '../utils/costants';
import { configuration } from '../configuration';
import { persistReducer, persistStore } from 'redux-persist';
import { SetTransformProject } from '../redux/reducers/project';

export const createStoreWithHistory = (history: any) => {
    const persistConfigProject = {
        key: 'EXOD-Project',
        storage,
        transforms: [SetTransformProject]
    }

    // shape the state structure
    const rootReducer = combineReducers({
        auth: AuthConfigReducer.authReducer,
        loader: loaderReducer,
        notification: notificationReducer,
        ui: uiReducer,
        administrationModal: administrationModalReducer,
        sort: sortReducer,
        filters: filtersReducer,
        notifySettings: notifySettingsReducer,
        sharedData: sharedDataReducer,
        modal: modalReducer,
        main: mainReducer,
        project: persistReducer(persistConfigProject, projectReducer),
        documents: documentsReducer,
        document: documentReducer,
        initialization: initializationReducer
    });

    // create the feature middleware array
    const featureMiddleware: any = [
        initializationMiddlewareHistory(history),
        sharedDataMiddlewareHistory(history),   
        modalMiddlewareHistory(history),
        filtersMiddlewareHistory(history),
        notifySettingsMiddleware,
        administrationModalMiddlewareHistory(history),
        projectMiddlewareHistory(history),
        documentsMiddlewareHistory(history),
        documentMiddlewareHistory(history),
        mainMiddleware          
    ];

    // create the core middleware array
    const coreMiddleware: any = [
        MicrosoftApiConfigMiddleWare.microsoftApiMiddleware,
        ApiConfigMiddleWare.apiMiddleware(clientId, '/.default', tokenClientId ),
        GraphQlConfigMiddleWare.graphQlMiddleware(),
        AuthConfigMiddleWare.authMiddlewareConfig(appName, clientId, redirectUri, tenantId, graphApiScopes, false, (isExternalApp) ? false : true),
        uiMiddlewareHistory(history),
        normalizeMiddleware,
        notificationMiddleware,
        LoggerConfigMiddleWare.loggerMiddleware
    ];

    // compose the middleware with additional (optional) enhancers,
    // DevTools.instrument() will enable dev tools integration
    const enhancer = compose(
        applyMiddleware(...featureMiddleware, ...coreMiddleware),
        //DevTools.instrument()
    );

    let store = createStore( rootReducer, {}, enhancer );
    let persistor = persistStore(store);

    return {store, persistor};
}