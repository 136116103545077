import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStoreWithHistory } from './redux/store';
import { createBrowserHistory } from "history";
import { PersistGate } from 'redux-persist/integration/react'
import './i18n';
import { configuration } from './configuration';

const history = (configuration.basename) ? createBrowserHistory({ basename: configuration.basename }) : createBrowserHistory(); //const history = createBrowserHistory();
export const storeObject = createStoreWithHistory(history);
const store = storeObject.store;
const persistor= storeObject.persistor;

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <App />
            </Router>
        </PersistGate>
    </Provider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

